import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  logoPath: string = "assets/img/logo.png";
  Slogan: string = "Vote pour nous, enculé.";

  constructor(config: NgbCarouselConfig) {
    config.interval = 7000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;

    this.Nael();
  }

  public Nael(){
    var today = new Date();
    if(today.getDate() == 25 && today.getMonth() == 11){
      this.logoPath = "assets/img/noel.png";
      this.Slogan = "Passe un joyeux Noël, enculé."
    }
  }

  ngOnInit() {

  }

}
